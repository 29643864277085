<!--
  功能：snap编辑广告
  时间：2025年01月21日 16:18:29
  版本：v1.0
-->
<template>
  <div>
    <el-form
      v-loading="adLoading"
      label-position="right"
      :model="adsData"
      label-width="107px"
      class="px-4"
    >
      <el-form-item
        label="广告名称"
        prop="adName"
      >
        <el-input
          disabled
          v-model="adsData.adName"
        ></el-input></el-form-item
      ><el-form-item
        label="状态："
        prop="status"
        v-if="nextEdit"
      >
        <el-switch
          v-model="adsData.status"
          active-text="开启"
          inactive-text="暂停"
          active-value="ACTIVE"
          inactive-value="PAUSED"
        ></el-switch></el-form-item
    ></el-form>
    <creatives
      v-loading="adLoading"
      ref="creatives"
      :edit="edit"
      :nextEdit="nextEdit"
      :creativeInfo="creativeInfo"
      :startTime="adsData.startTimeAdset"
      :enumConstants="enumConstants"
      :storageDisabled="storageDisabled"
      @setCreative="setCreative"
    ></creatives>
  </div>
</template>

<script>
import creatives from '@/views/snapchat/create/components/creatives/creatives.vue';
import { adForUpdate, updateAd } from '@/api/snapchat/edit';
import _ from 'lodash';
import mixinsSnapData from '@/views/snapchat/create/components/trackCreat/mixinsSnapData';
export default {
  // 组件名称
  name: 'ads',
  mixins: [mixinsSnapData],
  // 组件参数 接收来自父组件的数据
  props: {
    level: {
      type: String,
      default: '',
    },
    //单个编辑
    edit: {
      type: Boolean,
      default: false,
    },
    // 复制下一步编辑
    nextEdit: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    creative: {
      type: Object,
      default: () => {},
    },
    enumConstants: {
      type: Object,
      default: () => {},
    },
    contentData: {
      type: Object,
      default: () => ({}),
    },
    storageDisabled: {
      type: Boolean,
      default: false,
    },
  },
  // 局部注册的组件
  components: { creatives },
  // 组件状态值
  data() {
    return {
      adsData: {},
      creativeInfo: { infos: [{ headline: '' }] },
      adLoading: false,
      adsDataOriginal: {},
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    //单个编辑进来调接口获取数据
    async initData() {
      try {
        this.adLoading = true;
        const params = {
          accountId: this.editData.ad.adAccountId,
          level: this.level,
          adId: this.editData.ad.adId,
        };
        const res = await adForUpdate(params);
        this.initFormData(res.data);
      } catch (error) {
        console.log(error, 'error');
      } finally {
        this.adLoading = false;
      }
    },
    //初始化数据 单个编辑或者复制下一步编辑传入数据处理
    initFormData(adsData) {
      this.adsData = adsData;
      this.adsDataOriginal = _.cloneDeep(adsData);
      this.adsData.creative.webViewUrl = this.adsData.creative.landingPageUrl;
      this.creativeInfo = this.initCreativeInfo({ ...this.adsData.creative });
    },
    //初始化创意素材
    initCreativeInfo(creative) {
      // 故事广告
      if (creative.type == 'COMPOSITE') {
        creative.infos = creative.compositeMedia.map((v) => {
          return {
            uid: v.compositeCreativeId,
            mediaType: v.mediaType,
            url: v.compositeMediaUrl,
            headline: creative.headline,
          };
        });
        if (!creative.additionalCreativeInfo) {
          creative.additionalCreativeInfo = { hasPreview: false };
        }
        return creative;
      }
      creative.infos = [
        {
          uid: creative.mediaId,
          mediaType: creative.mediaType,
          url: creative.mediaUrl,
          headline: creative.headline,
        },
      ];
      if (creative.type == 'COLLECTION') {
        creative.additionalCreativeInfo.collectionMediaUrl =
          creative.additionalCreativeInfo.additionalCreativeCollectionInfo.collectionElementCreativeInfoList.map(
            (v) => {
              return v.mediaUrl;
            },
          );
      }
      if (!creative.additionalCreativeInfo) {
        creative.additionalCreativeInfo = { hasPreview: false };
      }
      return creative;
    },
    setCreative(creative) {
      this.creativeInfo = { ...this.creative, ...creative };
      if (this.creativeInfo.infos[0]?.originalFilename && this.creativeInfo.infos[0]?.md5) {
        this.adsData.adName = this.formatterName(
          this.creativeInfo.infos[0]?.originalFilename,
          this.creativeInfo.infos[0]?.md5,
        );
      }
    },
    async submitForm() {
      const adsData = await this.getFormData();
      if (!adsData) {
        return;
      }
      // 调用更新广告接口
      this.updateAdApi(adsData);
    },
    async getFormData() {
      const res = await this.checkForm();

      if (!res) {
        return;
      }
      //处理素材和缩略图和logo背景的url为后端需要的格式和比较url是否变化 变化后mediaId置空
      const creativeData = this.handelMediaUrl(this.creativeInfo);
      // 处理creativeId 如果创意中任意一项有变化则creativeId置空
      // const creativeData = this.handelIds(this.adsData.creative, mediaCreative);
      let creative = _.cloneDeep(creativeData);
      //删除前端展示用的数据
      delete creative.infos;
      if (creative?.additionalCreativeInfo?.collectionMediaUrl) {
        delete creative.additionalCreativeInfo.collectionMediaUrl;
      }
      // 将this.adsData里面的creative替换成新的creative
      let adsData = {
        ...this.adsData,
        creative,
      };

      return adsData;
    },
    // 校验表单
    checkForm() {
      return new Promise(async (resolve, reject) => {
        const valid = await this.$refs.creatives.creativesValidate();
        if (!valid) {
          return resolve(false);
        }
        return resolve(true);
      });
    },
    // 处理素材URL和ID
    handelMediaUrl(creative) {
      creative.headline = creative.infos[0].headline;
      // 故事广告处理素材 URL不一样就清空素材ID
      if (creative.type == 'COMPOSITE') {
        creative.compositeMedia = creative.infos.map((v, index) => {
          if (
            !this.edit ||
            v.url !== this.adsDataOriginal.creative.compositeMedia[index]?.compositeMediaUrl ||
            !this.adsDataOriginal.creative.compositeMedia[index]?.compositeMediaUrl
          ) {
            // creative.creativeId = '';
            return {
              mediaType: v.mediaType,
              compositeMediaUrl: v.url,
              mediaId: '',
              compositeCreativeId: '',
            };
          } else {
            return this.adsDataOriginal.creative.compositeMedia[index];
          }
        });
        //logo图改变清空logoMediaId
        if (
          (this.edit  || this.nextEdit ) &&
          creative.additionalCreativeInfo.hasPreview &&
          creative.additionalCreativeInfo?.logoMedia !== this.adsDataOriginal.creative.additionalCreativeInfo?.logoMedia
        ) {
          creative.additionalCreativeInfo.logoMediaId = '';
          // creative.creativeId = '';
        }        
        // 背景图改变清空backgroundMediaId
        if (
          (this.edit  || this.nextEdit ) &&
          creative.additionalCreativeInfo.hasPreview &&
          creative.additionalCreativeInfo?.backgroundMediaUrl !==
            this.adsDataOriginal.creative.additionalCreativeInfo?.backgroundMediaUrl
        ) {
          creative.additionalCreativeInfo.backgroundMediaId = '';
          // creative.creativeId = '';
        }
        return creative;
      }
      // else if (creative.type == 'WEB_VIEW') {
      // console.log(creative.infos[0].url == this.adsData.creative.mediaUrl,creative.infos[0].url , this.adsData.creative.mediaUrl);

      // }
      // 精选广告处理广告缩略图 URL不一样就清空广告缩略图ID
      else if (creative.type == 'COLLECTION') {
        //只要有一个URL变化，interactionZoneId就置空
        let count = 0;
        const collectionList =
          this.adsDataOriginal.creative.additionalCreativeInfo?.additionalCreativeCollectionInfo
            ?.collectionElementCreativeInfoList;
        if (!this.edit) {
          this.$set(creative.additionalCreativeInfo, 'additionalCreativeCollectionInfo', {
            collectionElementCreativeInfoList: [],
          });
        }
        creative.additionalCreativeInfo.additionalCreativeCollectionInfo.collectionElementCreativeInfoList =
          creative.additionalCreativeInfo.collectionMediaUrl.map((v, index) => {
            if (!this.edit || v !== collectionList[index]?.mediaUrl || !collectionList[index]?.mediaUrl) {
              count++;
              // creative.creativeId = '';
              return {
                mediaUrl: v,
                mediaId: '',
                creativeId: '',
              };
            } else {
              return collectionList[index];
            }
          });

        if (count !== 0) {
          creative.additionalCreativeInfo.additionalCreativeCollectionInfo.interactionZoneId = '';
        }
      } else {
        creative.additionalCreativeInfo = null;
      }
      // 单图单视频或者精选广告处理素材，如果素材不一样就清空素材ID
      if (creative.infos[0].url !== this.adsDataOriginal.creative.mediaUrl) {
        creative.mediaUrl = creative.infos[0].url;
        creative.mediaType = creative.infos[0].mediaType;
        creative.mediaId = '';
        // creative.creativeId = '';
      }

      return creative;
    },
    // 处理创意ID 有一项改变就清空creativeId
    handelIds(adsData, creative) {
      if (!creative.creativeId) {
        delete creative.info;
        return creative;
      }
      if (creative.callToAction !== adsData.callToAction || creative.headline !== adsData.headline) {
        creative.creativeId = '';
      }
      delete creative.info;
      return creative;
    },
    // 调用编辑广告接口
    async updateAdApi(adsData) {
      try {
        this.$showLoading();
        const res = await updateAd(adsData);
        if (res.code === 0) {
          this.$message.success('广告更新操作完成，请收到钉钉通知后查看');
          this.$emit('updateSuccess');
        }
      } catch (error) {
      } finally {
        this.$hideLoading();
      }
    },
    resetForm() {
      this.adsData = {};
      this.creativeInfo = {};
    },
  },
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    if (this.contentData?.accountId) {
      this.initFormData(this.contentData);
    }
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="sass" scoped></style>
