<!--
  功能：snapchat编辑广告组
  时间：2025年01月16日 17:35:57
  版本：v1.0
-->
<template>
  <div v-loading="adGroupLoading">
    <el-form
      label-position="right"
      :model="adGroupData"
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
      class="px-4"
    >
      <el-form-item
        label="广告组名称："
        prop="adsetName"
      >
        <el-input
          v-model="adGroupData.adsetName"
          clearable
          @change="editName"
          :disabled="!adGroupData.canEdit"
        ></el-input></el-form-item
      ><el-form-item
        label="状态："
        prop="status"
        v-if="nextEdit"
      >
        <el-switch
          v-model="adGroupOption.status"
          active-text="开启"
          inactive-text="暂停"
          active-value="ACTIVE"
          inactive-value="PAUSED"
          :disabled="!adGroupData.canEdit"
        ></el-switch></el-form-item
    ></el-form>
    <p class="font-lg font-bold px-4">定向</p>
    <audienceForm
      ref="audienceForm"
      v-if="adGroupTargeting"
      :enumConstants="enumConstants"
      :data="adGroupData"
      :edit="edit"
      :startTime="adGroupOption.startTime"
      :nextEdit="nextEdit"
      :target="adGroupTargeting"
      @setAudience="setAudience"
      :disabled="!adGroupData.canEdit"
    ></audienceForm>
    <budgetTime
      ref="budgetTime"
      v-model="adGroupOption"
      :enumConstants="enumConstants"
      :params="{}"
      :edit="edit"
      :nextEdit="nextEdit"
      :editData="editData"
      :partDisabled="partDisabled"
      :disabled="!adGroupData.canEdit"
    ></budgetTime>
  </div>
</template>

<script>
import { adsetForUpdate, updateAdset } from '@/api/snapchat/edit';
import audienceForm from '@/views/snapchat/create/components/audience/audienceForm/index.vue';
import budgetTime from '@/views/snapchat/create/components/trackCreat/budgetTime/index.vue';
import mixinsSnapData from '@/views/snapchat/create/components/trackCreat/mixinsSnapData';
export default {
  // 组件名称
  name: 'adGroup',
  mixins: [mixinsSnapData],
  // 组件参数 接收来自父组件的数据
  props: {
    level: {
      type: String,
      default: '',
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //单个编辑
    edit: {
      type: Boolean,
      default: false,
    },
    // 复制下一步编辑
    nextEdit: {
      type: Boolean,
      default: false,
    },
    enumConstants: {
      type: Object,
      default: () => {
        return {};
      },
    },
    contentData: {
      type: Object,
      default: () => ({}),
    },
    partDisabled: {
      type: Boolean,
      default: false,
    },
  },
  // 局部注册的组件
  components: { audienceForm, budgetTime },
  // 组件状态值
  data() {
    return {
      adGroupLoading: false,
      adGroupData: {},
      adGroupTargeting: {},
      adGroupOption: {},
      rules: {
        adsetName: [
          { required: true, message: '请输入广告组名称', trigger: ['blur', 'change'] },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: ['blur', 'change'] },
        ],
      },
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    // 获取到广告组接口要传的数据
    async getFormData() {
      const res = await this.checkForm();
      if (!res) {
        return;
      }
      let adGroupTargeting = { ...this.adGroupTargeting };
      adGroupTargeting = this.setAdGroup(adGroupTargeting);
      const params = {
        ...this.adGroupData,
        adGroupTargeting,
        adGroupOption: this.adGroupOption,
      };
      return params;
    },
    // 单个编辑进来调接口
    async initData() {
      this.$refs.audienceForm.clearValidate();
      try {
        this.adGroupLoading = true;
        const params = {
          accountId: this.editData.ad.adAccountId,
          level: this.level,
          adId: this.editData.ad.adGroupId,
        };
        const res = await adsetForUpdate(params);
        this.initFormData(res.data);
      } catch (error) {
      } finally {
        this.adGroupLoading = false;
      }
    },
    //初始化数据 单个编辑或者复制下一步编辑传入数据处理
    initFormData(data) {
      this.adGroupData = data;
      this.adGroupData.adAccountId = this.adGroupData.accountId;
      this.adGroupTargeting = { ...this.adGroupData.adGroupTargeting, ...{ id: this.adGroupData.id } };
      this.adGroupTargeting.geos = this.adGroupTargeting.geos.map((item) => {
        return item.countryCode;
      });
      this.$refs.audienceForm?.onChangeGeos(this.adGroupTargeting.geos);
      if (!this.adGroupTargeting.interests) {
        this.adGroupTargeting.interests = { custom: [] };
      } else {
        this.adGroupTargeting.interests = {
          custom: this.adGroupTargeting.interests[0].categoryId.map((item) => {
            return item;
          }),
        };
      }
      const segmentsList = [
        {
          // 自定义受众
          segmentId: [],
          operation: 'INCLUDE',
        },
        {
          segmentId: [],
          operation: 'EXCLUDE',
        },
      ]; // 自定义受众

      this.adGroupTargeting.segments?.map((item, index) => {
        if (item.operation === 'INCLUDE') {
          segmentsList[0].segmentId = item.segmentId;
        }
        if (item.operation === 'EXCLUDE') {
          segmentsList[1].segmentId = item.segmentId;
        }
      });
      this.adGroupTargeting.segments = segmentsList;
      this.adGroupOption = this.adGroupData.adGroupOption;
    },
    setAudience(v) {
      this.adGroupTargeting = { ...this.adGroupTargeting, ...v };
    },
    async submitForm() {
      const res = await this.checkForm();
      if (!res) {
        return;
      }
      const params = await this.getFormData();
      this.handelUpdateAdset(params);
    },
    //校验广告组数据
    checkForm() {
      return new Promise((resolve, reject) => {
        this.$refs.audienceForm.$refs.form.validate((valid) => {
          if (!valid) {
            return resolve(false);
          }
          this.$refs.ruleForm.validate((valid) => {
            if (!valid) {
              return resolve(false);
            }
            this.$refs.budgetTime.$refs.form.validate((valid) => {
              if (!valid) {
                return resolve(false);
              }
              return resolve(true);
            });
          });
        });
      });
    },
    // 单个编辑广告组保存
    async handelUpdateAdset(adGroupData) {
      try {
        this.$showLoading();
        const res = await updateAdset(adGroupData);
        if (res.code === 0) {
          this.$message.success('修改成功');
          this.$emit('updateSuccess');
        }
      } catch (error) {
      } finally {
        this.$hideLoading();
      }
    },
    resetForm() {
      this.adGroupData = {};
      this.adGroupTargeting = {};
      this.adGroupOption = {};
    },
    editName() {
      this.$emit('setName', 'adset', this.contentData.id, this.adGroupData.adsetName);
    },
  },
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    if (this.contentData?.accountId) {
      this.initFormData(this.contentData);
    }
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="sass" scoped></style>
