<!--
  功能：snapchat编辑广告
  时间：2025年01月16日 16:45:04
  版本：v1.0
-->
<template>
  <div>
    <el-form
      label-position="right"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
      class="demo-ruleForm"
      :disabled="!ruleForm.canEdit"
    >
      <el-form-item
        label="广告系列名称："
        prop="name"
      >
        <el-input
          v-model="ruleForm.name"
          clearable
          @change="editName"
        ></el-input></el-form-item
      ><el-form-item
        label="状态："
        prop="status"
      >
        <el-switch
          v-model="ruleForm.status"
          active-text="开启"
          inactive-text="暂停"
          active-value="ACTIVE"
          inactive-value="PAUSED"
        ></el-switch> </el-form-item
    ></el-form>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: 'campaign',
  // 组件参数 接收来自父组件的数据
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      ruleForm: {
        name: '',
        status: 'ACTIVE',
        canEdit: true,
      },
      rules: {
        name: [
          { required: true, message: '请输入广告系列名称', trigger: ['blur', 'change'] },
          { min: 1, max: 375, message: '长度在 1 到 375 个字符', trigger: ['blur', 'change'] },
        ],
      },
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    initFormData(data) {
      this.ruleForm = data;
    },
    async getFormData() {
      const res = await this.checkForm();
      if (!res) {
        return;
      }
      return this.ruleForm;
    },
    checkForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (!valid) {
            return resolve(false);
          }
          return resolve(true);
        });
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    editName() {
      this.$emit('setName', 'campaign', this.contentData.id, this.ruleForm.name);
    },
  },
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    this.ruleForm = {
      name: this.contentData.name,
      status: this.contentData.status,
      canEdit: this.contentData.canEdit,
    };
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="sass" scoped></style>
