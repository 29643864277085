<!--
  功能：snap编辑
  时间：2025年01月16日 17:05:37
  版本：v1.0
-->
<template>
  <ad-drawer
    :visible.sync="show"
    :configDraw="{ title: editTitle, size: 1000 }"
    showBtn
    @open="handelOpen"
    @close="handelClose"
    @sure="handelSure"
  >
    <adGroup
      ref="editSnap"
      v-if="level === 'adset' && show"
      v-loading="loading"
      :level="level"
      :editData="editData"
      :edit="true"
      :enumConstants="enumConstants"
      @updateSuccess="updateSuccess"
    ></adGroup>
    <ads
      ref="editSnap"
      v-if="level === 'ad' && show"
      v-loading="loading"
      :level="level"
      :editData="editData"
      :edit="true"
      :enumConstants="enumConstants"
      @updateSuccess="handelClose"
    ></ads>
  </ad-drawer>
</template>

<script>
import { adDrawer } from '@adCommonComponent';
import adGroup from './adGroup.vue';
import ads from './ads.vue';
import { getEnumConstants } from '@/api/snapchat/create.js';
export default {
  // 组件名称
  name: 'editSnap',
  // 组件参数 接收来自父组件的数据
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    level: {
      type: String,
      default: '',
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  // 局部注册的组件
  components: { adGroup, ads, adDrawer },
  // 组件状态值
  data() {
    return {
      loading: false,
      enumConstants: {
        promotionObjectTypes: [],
        objectives: [],
        optimizationGoals: [],
        bidStrategies: [],
        budgetTypes: [],
        conversionWindows: [],
        statuses: [],
        genders: [],
        callToActions: [],
      },
    };
  },
  // 计算属性
  computed: {
    editTitle() {
      switch (this.level) {
        case 'campaign':
          return '编辑广告系列';
        case 'adset':
          return '编辑广告组';
        case 'ad':
          return '编辑广告';
        default:
          return '';
      }
    },
  },
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    updateSuccess() {
      this.$emit('getList');
      this.handelClose();
    },
    handelSure() {
      this.$refs.editSnap.submitForm();
    },
    async handelOpen() {
      await this.getEnumConstants();
      this.$refs.editSnap.initData();
    },
    handelClose() {
      this.$emit('update:show', false);
      this.$refs.editSnap.resetForm();
    },
    getEnumConstants() {
      this.loading = true;
      getEnumConstants()
        .then((res) => {
          if (res.code == 0) {
            this.enumConstants = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="sass" scoped></style>
